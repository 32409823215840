window.onload = function() { document.body.className = ''; }
window.ontouchmove = function() { return false; }
window.onorientationchange = function() { document.body.scrollTop = 0; }
function mail(element) {
    element.href = 'mailto:mail' + '@' +  window.location.hostname.replace('www.', '');
}
document.getElementById('impressumLink').onclick = function() {
    document.getElementById('impressum').classList.add('visible');
};
document.getElementById('datenschutzLink').onclick = function() {
    document.getElementById('datenschutz').classList.add('visible');
};
Array.prototype.forEach.call(document.getElementsByClassName('close'), function(elem) {
    elem.onclick = function(e) {
        console.log(e.target.parentNode);
        e.target.parentNode.classList.remove('visible');
    }
});
